import axios from 'axios';



var url = process.env.VUE_APP_BASEURL;


let instance = axios.create({
    baseURL: url
})

instance.interceptors.request.use(function (config) {
    // if (config.url === '/api/v1/user/token') {
    //     config.headers = {  // 登录 --- 需清空Authorization字段

    //     };
    // } else {
    //     config.headers = {
    //         'Authorization': localStorage.getItem('token') as string,
    //     }
    // }

    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default instance;