import { test_config } from '@/config/config';



// URL地址解析
export function getJsUrl (url: any): any {
    var pos, str, para, parastr;
    var array: any = []
    str = location.href;
    parastr = str.split("?")[ 1 ];
    var arr = parastr.split("&");
    for (var i = 0; i < arr.length; i++) {
        array[ arr[ i ].split("=")[ 0 ] ] = arr[ i ].split("=")[ 1 ];
    }
    return array;
}

// 复制
export function copy (data: any) {
    let OrderNumber = data;
    let newInput = document.createElement("input");
    newInput.value = OrderNumber;
    document.body.appendChild(newInput);
    newInput.select();
    document.execCommand("Copy");
    newInput.remove();
}

// 引入本地图片
export function importImage (url: any) {
    return new URL(url, import.meta.url).href
}

// ieme
export function getImei() {
    let imei = localStorage.getItem("uniimei");
    if(imei){
        return imei;
    }
    imei = "self" + Date.now() + "_" + Math.random();
    localStorage.setItem("uniimei", imei);
    return imei;
};

// 获取配置
export function getConfig() {
    let config = JSON.parse(localStorage.getItem('config') as string);
    if(config){
        return {...config};
    }else{
        return undefined;
    }
}

// 设置全局配置
export function saveConfig(value:any){
    let config_obj = test_config['84'];
    for (let key in test_config) {
      if (test_config.hasOwnProperty(key)) {
        if (test_config[key].country === value || test_config[key].phoneAreaCode === value) {
          config_obj = test_config[key];
        }
      }
    }
    localStorage.setItem('config',JSON.stringify(config_obj));
}