import createPersistedState from 'vuex-persistedstate';
import { type Store, createStore } from 'vuex'
import setting from './modules/setting'; // 设置模块
import type { SettingState } from './modules/setting/types';
import type { InjectionKey } from 'vue';

/**
 * 声明全局仓库的状态ts类型
 */
export interface State {
  setting:SettingState
}

// 定义useStroe依赖注入的key
export const useStoreKey:InjectionKey<Store<State>>=Symbol()

/**
 * 根仓库
 */
export default createStore({
  modules: {
    setting
  },
  plugins:[
      createPersistedState({
          storage:window.localStorage
      })
  ]
})
