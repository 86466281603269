import { createI18n } from 'vue-i18n';
import CN from './CN';
import US from './US';

export enum LOCALE_VALUES {
    CN = 'CN',
    US = 'US'
}

export const LOCALE_OPTIONS = [
    { label: '中文', value: LOCALE_VALUES.CN },
    { label: 'English', value: LOCALE_VALUES.US },
];



const stateJSON = localStorage.getItem('vuex')
let defaultLocale = 'CN'

// 根据本地数据初始化语言设置
if (stateJSON) {
    const state = JSON.parse(stateJSON)
    if (typeof state === 'object' && state.setting) {
        // 仓库数据是对象
        if (state.setting.language) {
            // language存在
            if (LOCALE_OPTIONS.some(ele => ele.value === state.setting.language)) {
                // language取值正确
                defaultLocale = state.setting.language
            }
        }
    }
}

const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: LOCALE_VALUES.US,
    allowComposition: true,
    messages: {
        CN,
        US
    }
});

console.log(`当前为:${ i18n.global.locale }`)

export default i18n