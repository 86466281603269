import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN'

import { LightVars, DarkVars } from '@/config/CssVar';
import type { StoreOptions } from 'vuex';
import type { SettingState } from './types';

const setting: StoreOptions<SettingState> = {
  // @ts-ignore
  namespaced: true,
  state () {
    return {
      isDark: true,
      language: 'CN'
    }
  },
  actions: {
    /**
     * 设置语言
     * @param context 
     * @param value 
     */
    toSetLanguage (context,value) {
      context.commit('setLanguage', value)
      context.commit('setVantLanguage')
    }
  },
  mutations: {
    /**
     * 设置主题
     * @param state 
     * @param value 
     */
    setTheme (state, value: boolean) {
      state.isDark = value
      const html = document.documentElement
      const vars = value ? DarkVars : LightVars
      Object.entries(vars).forEach(([ key, value ]) => {
        html.style.setProperty(key, value)
      })
    },
    /**
     * 设置语言
     * @param state 
     * @param value 
     */
    setLanguage (state, value: string) {
      state.language = value
      console.log('设置语言')
    },
    /**
     * 根据仓库的语言设置vant组件库的语言
     */
    setVantLanguage (state) {
      console.log('设置vant语言')
      if (state.language === 'CN') {
        Locale.use('zh-CN', zhCN);
      } else {
        Locale.use('en-US', enUS);
      }
    }
  },
  getters: {
    currentLanguage (state) {
      return state.language === 'CN' ? '中文' : 'English'
    }
  }
}

export default setting