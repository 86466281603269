interface Config {
    tips: string;
    gameid: number;
    zoneid: number;
    lobbyid: number;
    phoneAreaCode: number;
    country: string;
    language: string;
    appKey: string;
    login_url: string;
}
  
interface ConfigMap {
    [key: string]: Config;
}

export const config:ConfigMap = {
    
}

export const test_config:ConfigMap = {
    84:{
        'tips':'越南测试服',
        'gameid': 8001,
        'zoneid': 1001,
        'lobbyid': 8001,
        'phoneAreaCode': 84,
        'country': 'VN',
        'language': 'vn',
        'appKey': '15Gf6YysvNQ00EPvBDkEJpP1sdEYBXno',
        'login_url': 'https://test.login.facegaclub.com/httplogin'
    },
    63:{
        'tips':'菲律宾测试服',
        'gameid': 8001,
        'zoneid': 1001,
        'lobbyid': 8001,
        'phoneAreaCode': 63,
        'country': 'VN',
        'language': 'vn',
        'appKey': '15Gf6YysvNQ00EPvBDkEJpP1sdEYBXno',
        'login_url': 'https://test.login.facegaclub.com/httplogin'
    },
    52:{
        'tips':'泰国测试服',
        'gameid': 8001,
        'zoneid': 1001,
        'lobbyid': 8001,
        'phoneAreaCode': 52,
        'country': 'VN',
        'language': 'vn',
        'appKey': '15Gf6YysvNQ00EPvBDkEJpP1sdEYBXno',
        'login_url': 'https://test.login.facegaclub.com/httplogin'
    }
}