import { createApp, onErrorCaptured } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/index.css'
import 'vant/lib/index.css'
import 'swiper/css'
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/grid';
import './style.css';
import i18n from './locale'
import Vant from '../node_modules/vant/lib';
import { useStoreKey } from './store'

createApp(App).use(store,useStoreKey).use(router).use(i18n).use(Vant).mount('#app');