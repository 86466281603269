import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import i18n from '@/locale/index';
import { getCountry } from "@/api/user";
import { getConfig,saveConfig } from '@/utils/function';




const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/HomeView.vue'),
    redirect: '/home',
    meta: {
      title: 'index'
    },
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/index.vue'),
        meta: {
          title: 'home'
        }
      },
      {
        path: '/activity',
        name: 'activity',
        component: () => import('../views/activity/index.vue'),
        meta: {
          title: 'activity'
        }
      },
      {
        path: '/wallt',
        name: 'wallt',
        component: () => import('../views/wallt/index.vue'),
        meta: {
          title: 'wallt'
        }
      },
      {
        path: '/acting',
        name: 'acting',
        component: () => import('../views/acting/index.vue'),
        meta: {
          title: 'acting'
        }
      },
      {
        path: '/mine',
        name: 'mine',
        component: () => import('../views/mine/index.vue'),
        meta: {
          title: 'mine'
        }
      },
    ]
  },
  // ------------  ----------------
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
    meta: {
      title: 'login'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register.vue'),
    meta: {
      title: 'register'
    }
  },
  {
    path: '/find',
    name: 'find',
    component: () => import('../views/login/find.vue'),
    meta: {
      title: 'find'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/notFound/notfound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    redirect: '/404'
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})

router.beforeEach((to: any, from: any) => {
  document.title = to.meta.title ? to.meta.title : '';

  if(!getConfig()){
    onGetCurrentIPCountry();
  };
})

// 获取当前访问者国家
const onGetCurrentIPCountry = () => {
  getCountry().then((res:any)=>{
    saveConfig(res.data.data.country);
  });
}



export default router
